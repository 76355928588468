.pac-man {
    border-radius: 50%;
    margin: 20% auto auto 40%;
    border-radius: 100em 100em 0 0;
    background: #f00;
    transform-origin: bottom;
    -webkit-animation: eating-top 0.5s infinite;
    animation: eating-top 0.5s infinite;
}

.pac-man,
.pac-man::before {
    width: 70px;
    height: calc(70px/2);
    background: skyblue;
}

.pac-man::before {
    content: '';
    display: block;
    margin-top: calc(70px/2);
    position: absolute;
    transform-origin: top;
    border-radius: 0 0 100em 100em;
    transform: rotate(80deg);
    -webkit-animation: eating-bottom 0.5s infinite;
    animation: eating-bottom 0.5s infinite;
}

.pac-man::after {
    position: absolute;
    border-radius: 100em;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    margin-top: calc((70px / 2) - 10px);
    margin-left: calc((70px / 2) - 10px);
    transform-origin: center;
    -webkit-animation: center 0.5s infinite, ball 0.5s -0.33s infinite linear;
    animation: center 0.5s infinite, ball 0.5s -0.33s infinite linear;
}

@-webkit-keyframes eating-top {
    0% {
        transform: rotate(-40deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-40deg);
    }
}

@keyframes eating-top {
    0% {
        transform: rotate(-40deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-40deg);
    }
}

@-webkit-keyframes eating-bottom {
    0% {
        transform: rotate(80deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(80deg);
    }
}

@keyframes eating-bottom {
    0% {
        transform: rotate(80deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(80deg);
    }
}

@-webkit-keyframes center {
    0% {
        transform: rotate(40deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(40deg);
    }
}

@keyframes center {
    0% {
        transform: rotate(40deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(40deg);
    }
}

@-webkit-keyframes ball {
    0% {
        opacity: 0.7;
        box-shadow: 70px 0 0 0 skyblue, 120px 0 0 0 skyblue, 170px 0 0 0 skyblue, 220px 0 0 0 skyblue;
    }

    100% {
        box-shadow: 20px 0 0 0 skyblue, 70px 0 0 0 skyblue, 120px 0 0 0 skyblue, 170px 0 0 0 skyblue;
    }
}

@keyframes ball {
    0% {
        opacity: 0.7;
        box-shadow: 70px 0 0 0 skyblue, 120px 0 0 0 skyblue, 170px 0 0 0 skyblue, 220px 0 0 0 skyblue;
    }

    100% {
        box-shadow: 20px 0 0 0 skyblue, 70px 0 0 0 skyblue, 120px 0 0 0 skyblue, 170px 0 0 0 skyblue;
    }
}